import axios from 'axios';
import md5 from "/utils/md5/md5.js";

axios.defaults.timeout = process.env.VUE_APP_TIMEOUT; // 设置请求超时时间
axios.defaults.headers["Content-Type"] = "application/json"; // 设置请求头
axios.defaults.headers.channel = process.env.VUE_APP_CHANNEL; // 设置渠道头

function requestEncrypt(data) {
    let secret = process.env.VUE_APP_SECRET; // 获取密钥
    let _str = '';
    let sorted = {};
    let keys = Object.keys(data);
    keys.sort(); // 对键进行排序
    keys.forEach((key) => {
        _str += key + data[key]; // 拼接字符串
    });
    _str = secret + _str + secret; // 加密字符串
    return md5.hex_md5_32Upper(_str); // 返回 MD5 加密结果
}

export default function request(options) {
    options.data.timestamp = (new Date()).valueOf(); // 添加时间戳
    options.data.app_key = process.env.VUE_APP_KEY; // 添加应用密钥
    options.data.sign = requestEncrypt(options.data); // 生成签名

    if (options.method === 'get') {
        let params = '';
        let keys = Object.keys(options.data);
        keys.forEach((key) => {
            params += key + '=' + options.data[key] + '&'; // 拼接 GET 请求参数
        });
        options.url += '?' + params; // 将参数添加到 URL
    }

    let userInfo = localStorage.getItem("userInfo");
    let auth = '';
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        auth = "Bearer " + userInfo.token; // 获取用户的 Bearer Token
    }

    axios.defaults.headers.AUTHORIZATION = auth; // 设置 Authorization 头
console.log(options);
    return new Promise((resolve, reject) => {
        axios(options).then(res => {
            resolve(res.data); // 成功时解析数据
        }).catch(error => {
            reject(error); // 失败时拒绝 Promise
        });
    });
}
